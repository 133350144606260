import React, { useEffect } from "react"
import axios from "axios"
import { useState } from "react"
import CreateModal from "./CreateModal"
import UpdateModal from "./UpdateModal"
import { Link } from 'react-router-dom';
import { Table } from "react-bootstrap";
class CustomPromptUtil extends React.Component {

    // setting the initial state for the modal as hidden
    state = {
        showModal: false,
        showResponseData: false,
        responseData: [{
            name: "",
            email: ""
        }],
        modalActionValue: "",
        isLoading: false,
        isLoadingAccord: false,
        responseAccord: [],
        showResponseAccordianData: false
    }

    NumberToText = (n) => {
        if (n < 0)
          return false;
        let single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
        let double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
        let below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
        if (n === 0) return 'Zero'
        let result = this.translate(n, single_digit, double_digit, below_hundred) 
        return result;
    }
     translate(n, single_digit, double_digit, below_hundred) {
        let word = ""
          if (n < 10) {
            console.log("In If:", n)
              word = single_digit[n] + ' '
          }
          else if (n < 20) {
              word = double_digit[n - 10] + ' '
          }
          else if (n < 100) {
             let rem = this.translate(n % 10)
              word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem
          }
          else if (n < 1000) {
              word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + this.translate(n % 100)
          }
          return word      
        }

    // creating a toggle function that will be passed down to any children of this container that need to toggle the modal on or off
    toggleModal = (event) => {
        this.setState({ showModal: !this.state.showModal })
        let IncrValue = event.target.value
        // IncrValue = ++IncrValue
        this.setState({ modalActionValue: IncrValue })
    }
    componentDidMount = async () => {
        await axios.post("/openai/gpt/getPromptList")
            .then((res) => {
                this.setState({ showResponseData: true })
                this.setState({ responseData: res.data })
                // console.log("Response:", this.state.responseData)
            })
            .catch(err => console.log(err))
    }
    handleDelete = (id) => {
        let confirm = window.confirm("Are you sure to delete?")
        if (confirm) {
            axios.delete(`/openai/gpt/deletePrompt/${id}`).then((response) => {
                if (response.status == 200) {
                    window.location.reload();
                }
            })
        }
    }
    handleAccordianClick = async (applicationId, index) => {
        this.setState({ isLoading: true })
        try {
            await axios.post("/openai/gpt/getPromptHistory", { application_id: applicationId })
                .then((res) => {
                    this.setState({ showResponseData: true })
                    this.setState({ responseAccord: res.data })
                    this.setState({ isLoadingAccord: true })
                    this.setState({ showResponseAccordianData: true })
                    console.log("Response:", this.state.responseAccord)
                })
                .catch(err => console.log(err))

        } catch (e) { }
    }
    handleAccordionToggle(value){
        console.log("Value:", value)
    }
    //
    render() {
        return (
            <div className="accordion ">
                <h3>Custom Prompt Utility</h3>
                {/* to="/CustomPromptUtility/Create" */}
                <button className="btn btn-success my-3" onClick={(event) => this.toggleModal(event)} value="Create">Create +</button>
                <div className="gridtable">
                    <table className="table table-bordered">
                        <thead className="">
                            <tr>
                                <th>Application Id</th>
                                <th>Application Name</th>
                                <th>System Prompt</th>
                                <th>User Prompt</th>
                                <th>Modal</th>
                                <th>Created Date</th>
                                <th>Created By</th>
                                <th>Modified Date</th>
                                <th>Modified By</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>

                            {this.state.showResponseData && this.state.responseData.map((res, index) => {
                                index++;
                                 let accordCollapse = res.records ? "collapse"+this.NumberToText(index-1).trim() : "collapse"+this.NumberToText(index).trim()
                                 let hrefCollapse = "#"+accordCollapse.trim();
                                return (
                                    res.records ?
                                        <tr id={accordCollapse} className="collapse" data-bs-parent="#accordion">
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.records.application_id}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.records.application_name}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.records.prompt_system}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.records.prompt_user}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.records.modal}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.records.created_date}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.records.created_by}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.records.modified_date}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.records.modified_by}</td>
                                            <td>

                                                <button className="btn btn-info" style={{ margin: "6px" }} onClick={(event) => this.toggleModal(event)} value={res.records.application_id}>Update</button>
                                                <button className="btn btn-danger" onClick={e => this.handleDelete(res.records.application_id)}>Delete</button>
                                            </td>
                                        </tr> :
                                        <tr className="card-header" onClick={this.handleAccordionToggle(accordCollapse)}>
                                            {/* <td className="" data-bs-toggle="collapse" href="#collapseOne" colSpan="5">
            first row
            </td> */}
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse}  >{res.application_id}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.application_name}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.prompt_system}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.prompt_user}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.modal}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.created_date}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.created_by}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.modified_date}</td>
                                            <td className="" data-bs-toggle="collapse" href={hrefCollapse} >{res.modified_by}</td>
                                            <td>

                                                <button className="btn btn-info" style={{ margin: "6px" }} onClick={(event) => this.toggleModal(event)} value={res.application_id}>Update</button>
                                                <button className="btn btn-danger" onClick={e => this.handleDelete(res.application_id)}>Delete</button>
                                            </td>
                                        </tr>

                                )
                            })
                            }

                            <tr className="card-header">
                                <td className="collapsed" data-bs-toggle="collapse" href="#collapseTwo" colSpan="5">
                                    second row
                                </td>
                            </tr>
                            <tr id="collapseTwo" className="collapse" data-bs-parent="#accordion">
                                <td className="card-body" colSpan="5">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </td>
                            </tr>



                        </tbody>
                    </table>
                </div>
                {this.state.modalActionValue != ""
                    ? this.state.modalActionValue == "Create" ? <CreateModal isOpen={this.state.showModal} toggle={this.toggleModal} /> : <UpdateModal isOpen={this.state.showModal} toggle={this.toggleModal} actionValue={this.state.modalActionValue} />
                    : ""}
            </div>
        )
    }
}

export default CustomPromptUtil;
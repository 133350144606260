import React, { Component } from 'react';
import './css/CustomPagination.css'; // Import the CSS file for styling

class CustomPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      itemsPerPage: 5,
    };
  }

  handleClick = (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });
  };

  render() {
    console.log(this.props)
    const { data } = this.props;
    const { currentPage, itemsPerPage } = this.state;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="pagination-container">
        <ul className="items-list">
          {currentItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <ul className="pagination">
          {pageNumbers.map((pageNumber) => (
            <li key={pageNumber}>
              <a
                href="#"
                onClick={() => this.handleClick(pageNumber)}
                className={pageNumber === currentPage ? 'active' : ''}
              >
                {pageNumber}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default CustomPagination;

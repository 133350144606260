import React from "react"
import { useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
function Create() {
    const [inputData, setInputData] = useState({
        name: "",
        email: ""
    })
    const navigate = useNavigate();


    const handleSubmit = (event) => {
        event.preventDefault()
        axios.post('http://localhost:3030/users', inputData)
        .then(res => {
            alert("Data Inserted Successfully!")
            navigate("/CustomPromptUtility/CustomPrompt")
        })
    }
    return(
        <div className="d-flex w-100 vh-100 justify-content-center align-items-center">
            <div className="w-50 border bg-secondary text-white p-5">
                <form onSubmit={handleSubmit} id="CreateNew">
                    <div>
                        <label htmlFor="appName">Application Name</label>
                        <input type="text" name="appName" className="form-control" 
                        onChange={e => setInputData({...inputData, appName: e.target.value})}/>
                    </div>
                    <div>
                        <label htmlFor="systemPrompt">System Prompt</label>
                        <input type="text" name="systemPrompt" className="form-control" 
                        onChange={e => setInputData({...inputData, systemPrompt: e.target.value})}/>
                    </div>
                    <div>
                        <label htmlFor="userPrompt">User Prompt</label>
                        <input type="text" name="userPrompt" className="form-control" 
                        onChange={e => setInputData({...inputData, userPrompt: e.target.value})}/>
                    </div>
                    <div>
                        <label htmlFor="modal">Modal</label>
                        <input type="text" name="modal" className="form-control" 
                        onChange={e => setInputData({...inputData, modal: e.target.value})}/>
                    </div>
                    <div>
                        <label htmlFor="systemPrompt">Created By</label>
                        <input type="text" name="createdBy" className="form-control" 
                        onChange={e => setInputData({...inputData, createdBy: e.target.value})}/>
                    </div><br />
                    <button className="btn btn-info">Submit</button>
                </form>
            </div>
        </div>
    )
}

export default Create;
import React from "react";
import { useState } from "react";
import axios from "axios";
import Select from "react-select"
import "../style.css"
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { useEffect } from "react";

const UpdateModal = (props) => {

  console.log("updateResponseData appName:", props)
  let a
  a = props.state.responseData.find(item => item.application_id == props.actionValue)
  let actionItem = props.actionValue;
  // console.log("actionItem:", actionItem)
  const [inputData, setInputData] = useState({
    application_id: actionItem,
    application_name: a.application_name,
    feature_name: a.feature_name,
    prompt_system: a.prompt_system,
    prompt_user: a.prompt_user,
    modal: a.model,
    modified_by: a.modified_by,
    form_type: "update"
  });
  const [showModalStruct, setshowModalStruct] = useState(false)
  const [applicationName, setApplicationName] = useState([])
  const [defaultApplicationName, setDefaultApplicationName] = useState()
  const [featureName, setFeatureName] = useState([])
  const [defaultFeatureName, setDefaultFeatureName] = useState()
  const [errors, setErrors] = useState({});
  const [modalName, setModalName] = useState([])
  const [defaultModalName, setDefaultModalName] = useState()

  useEffect(async () => {
    await axios
      .post(`/openai/gpt/getPromptList`, {
        application_id: actionItem,
      })
      .then((res) => {
        let applicationName, modelName
        if (res.data[0].application_name) {
          applicationName = res.data[0].application_name
        } else {
          applicationName = ""
        }
        if (res.data[0].model) {
          modelName = res.data[0].model
        } else {
          modelName = ""
        }
        const inputDataValues = {
          application_id: res.data[0].application_id ? res.data[0].application_id : "",
          application_name: res.data[0].application_name ? res.data[0].application_name : "",
          feature_name: res.data[0].feature_name ? res.data[0].feature_name : "",
          prompt_system: res.data[0].prompt_system ? res.data[0].prompt_system : "",
          prompt_user: res.data[0].prompt_user ? res.data[0].prompt_user : "",
          modal: res.data[0].model ? res.data[0].model : "",
          modified_by: res.data[0].modified_by ? res.data[0].modified_by : "",
          form_type: "update"
        };
        setInputData(inputDataValues);
      });
    if (props.state.applicationname)
      setApplicationName(props.state.applicationname)
    if (props.state.featurename)
      setFeatureName(props.state.featurename)
    if (props.state.modelname)
      setModalName(props.state.modelname)

    const defaultAppName = props.state.applicationname.find(item => item.label == inputData.application_name)
    // console.log("defaultAppName:", props.state.applicationname)
    // console.log("inputData.application_name:", inputData.application_name)
    setDefaultApplicationName(defaultAppName)
    const defaultFeatureName = props.state.featurename.find(item => item.label == inputData.feature_name)
    // console.log("defaultFeatureName:", defaultFeatureName)
    setDefaultFeatureName(defaultFeatureName)
    const defaultModName = props.state.modelname.find(item => item.id == inputData.modal)
    // console.log("defaultModName:", defaultModName)
    setDefaultModalName(defaultModName)
  }, []);
  const validateForm = (event) => {
    const newErrors = {};

    if (!inputData.application_name) {
      newErrors.application_name = 'Application name required'
    } else if (!inputData.feature_name) {
      newErrors.feature_name = 'Feature name required'
    } else if (!inputData.modal) {
      newErrors.modal = 'Model name required'
    } else if (!inputData.modified_by) {
      newErrors.modified_by = 'Updated By required'
    } else if (!inputData.prompt_user) {
      newErrors.prompt_user = 'User Prompt required'
    } else if (!inputData.prompt_system) {
      newErrors.prompt_system = 'System Prompt required'
    }

    setErrors(newErrors)

    return newErrors

  }
  const handleSubmit = (event) => {
    event.preventDefault();
    let validate = validateForm(event);
    console.log("Response:", validate)
    if (Object.keys(validate).length == 0) {
      axios
        .post(`/openai/gpt/addPrompt`, inputData)
        .then((res) => {
          alert(res.data.result);
          window.location.reload();
        });
    }
  };
  const handleClose = (event) => {
    // props.toggle
    window.location.reload();
  }
  return (
    <div>
      <Modal show={props.isOpen} className="modal-dialog-style">
        <Modal.Header>
          <Modal.Title>
            <div className="container">
              <div className="row col-sm-12">
                <div id="modal-message" className="col-sm-6">
                  Update
                </div>
              </div>
            </div>
          </Modal.Title>
          <div
            id="update-modal-exit"
            className="col-sm-2"
            onClick={(event) => handleClose(event)}
          >
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 mrbot10">
                <label htmlFor="application_name" className="col-md-12">
                  Application Name
                </label>
                <Select
                  id="application_name"
                  name="application_name"
                  options={applicationName}
                  value={defaultApplicationName}
                  onChange={(event) => {
                    setDefaultApplicationName(event)
                    setInputData({ ...inputData, application_name: event });
                  }} />
                {errors.application_name && <p>{errors.application_name}</p>}
                <input id="application_id" name="application_id" value={inputData.application_id} type="hidden" />
                <input id="form_type" name="form_type" value="update" type="hidden" />
              </div>
              <div className="col-md-6 mrbot10">
                <label htmlFor="feature_name" className="col-md-12">
                  Feature Name
                </label>
                <Select
                  id="feature_name"
                  name="feature_name"
                  options={featureName}
                  value={defaultFeatureName}
                  onChange={(event) => {
                    setDefaultFeatureName(event)
                    setInputData({ ...inputData, feature_name: event });
                  }} />
                {errors.feature_name && <p>{errors.feature_name}</p>}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mrbot10">
                <label htmlFor="modal" className="col-md-12">
                  Model
                </label>
                <Select
                  id="modal"
                  name="modal"
                  options={modalName}
                  value={defaultModalName}
                  onChange={(event) => {
                    setDefaultModalName(event)
                    setInputData({ ...inputData, modal: event });
                  }} />
                {errors.modal && <p>{errors.modal}</p>}
              </div>
              <div className="col-md-6 mrbot10">
                <label htmlFor="modified_by" className="col-md-12">
                  Updated By
                </label>
                <input
                  type="text"
                  name="modified_by"
                  id="modified_by"
                  className="form-control"
                  value={inputData ? inputData.modified_by : ""}
                  onChange={(e) => {
                    setInputData({ ...inputData, modified_by: e.target.value });
                  }}
                />
                {errors.modified_by && <p>{errors.modified_by}</p>}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mrbot10">
                <label htmlFor="prompt_user">User Prompt</label>

                <textarea
                  type="text"
                  name="prompt_user"
                  value={inputData ? inputData.prompt_user : ""}
                  className="form-control"
                  onChange={(e) => {
                    setInputData({ ...inputData, prompt_user: e.target.value });
                  }}
                  cols="60" rows="4"
                />
                {errors.prompt_user && <p>{errors.prompt_user}</p>}
              </div>

            </div>
            <div>
              <label htmlFor="prompt_system">System Prompt</label>
              <textarea
                name="prompt_system"
                value={inputData ? inputData.prompt_system : ""}
                className="form-control"
                onChange={(e) => {
                  setInputData({ ...inputData, prompt_system: e.target.value });
                }}
                cols="60" rows="4"
              />
              {errors.prompt_system && <p>{errors.prompt_system}</p>}
            </div>
            <br />
            <button className="btn btn-info">
              Submit
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdateModal;

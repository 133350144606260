import React, { useEffect } from "react"
import axios from "axios"
import { useState } from "react"
import CreateModal from "./CreateModal"
import UpdateModal from "./UpdateModal"
import { Link } from 'react-router-dom';

class CustomPrompt extends React.Component {

  // setting the initial state for the modal as hidden
  state = {
    showModel: false,
    showResponseData: false,
    responseData: [{
      name: "",
      email: ""
    }],
    showModel: "",
    modelname: [],
    applicationname: [],
    isLoading: false,
    isLoadingAccord: false,
    responseAccord: [],
    showResponseAccordianData: false
  }

  // creating a toggle function that will be passed down to any children of this container that need to toggle the modal on or off
  toggleModel = (event) => {
    this.setState({ showModel: !this.state.showModel })
    let IncrValue = event.target.value
    // IncrValue = ++IncrValue
    this.setState({ showModel: IncrValue })
  }
  componentDidMount = async () => {
    await axios.post("/openai/gpt/getPromptList")
      .then((res) => {
        this.setState({ showResponseData: true })
        this.setState({ responseData: res.data })
        // console.log("Response:", this.state.responseData)
      })
      .catch(err => console.log(err))

      let applicationArr = []
      let modelArr = []
    await axios
      .post(`/openai/gpt/getApplicationName`, {})
      .then((res) => {
        res.data.map(d => applicationArr.push({
          "value": d.application_id,
          "label": d.application_name
        }))
        this.setState({ applicationname: applicationArr });
      });
      await axios
      .post(`/openai/gpt/getModalName`, {})
      .then((res) => {
        res.data.map(d => modelArr.push({
          "id": d.model_name,
          "label": d.model_name
        }))
        this.setState({ modelname: modelArr })
      });
  }
  handleDelete = (id) => {
    let confirm = window.confirm("Are you sure to delete?")
    if (confirm) {
      axios.delete(`/openai/gpt/deletePrompt/${id}`).then((response) => {
        if (response.status == 200) {
          window.location.reload();
        }
      })
    }
  }
  handleAccordianClick = async (applicationId, index) => {
    this.setState({ isLoading: true })
    try {
      await axios.post("/openai/gpt/getPromptHistory", {application_id: applicationId})
        .then((res) => {
          this.setState({ showResponseData: true })
          this.setState({ responseAccord: res.data })
          this.setState({ isLoadingAccord: true })
          this.setState({ showResponseAccordianData: true })
        })
        .catch(err => console.log(err))

    } catch (e) { }
  }
  //
  render() {
    return (
      <div className="container ">
        <h3>Custom Prompt Utility</h3>
        {/* to="/CustomPromptUtility/Create" */}
        <button className="btn btn-success my-3" onClick={(event) => this.toggleModel(event)} value="Create">Create +</button>
        <div className="gridtable">
          <table className="table table-bordered">
            <thead className="">
              <tr>
                <th>Sr. No.</th>
                <th>Application Name</th>
                <th>System Prompt</th>
                <th>User Prompt</th>
                <th>Model</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>

              {/* console.log(key, this.state.responseData[key]); */}
              {this.state.showResponseData && this.state.responseData.map((res, index) => {
                return (
                  
                  <tr key={res.application_id} value={res.application_id} onClick={(event) => this.handleAccordianClick(res.application_id, index)}>
                    <td>{index}</td>
                    <td><div disabled value={res.id}></div>{res.application_name}</td>
                    <td>{res.prompt_system}</td>
                    <td>{res.prompt_user}</td>
                    <td>{res.model}</td>
                    <td>

                      <button className="btn btn-info" style={{ margin: "6px" }} onClick={(event) => this.toggleModel(event)} modelname={this.state.modelname} applicationname={this.state.applicationname} value={res.application_id}>Update</button>
                      <button className="btn btn-danger" onClick={e => this.handleDelete(res.id)}>Delete</button>
                    </td>
                  </tr>
                )
              })
              }
              


            </tbody>
          </table>
        </div>
        {this.state.showModel != ""
          ? this.state.showModel == "Create" ? <CreateModal isOpen={this.state.showModel} toggle={this.toggleModel} /> : <UpdateModal isOpen={this.state.showModel} state={this.state} toggle={this.toggleModel} actionValue={this.state.showModel} />
          : ""}
      </div>
    )
  }
}

export default CustomPrompt;
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./style.css"
import CustomPrompt from "./CustomPromptUtility/CustomPrompt"
import CustomPromptUtil from "./CustomPromptUtility/CustomPromptUtil"
import Create from "./CustomPromptUtility/Create"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/collapse.js";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={App} />
        <Route path="/CustomPromptUtility/CustomPrompt" Component={CustomPrompt} />
      </Routes>       
      </BrowserRouter>
    
  </React.StrictMode>,
  document.getElementById('root')
);



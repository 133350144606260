import React from "react"
import { useState } from "react"
import axios from "axios"
import Select from "react-select"
import { useNavigate } from "react-router-dom"
import {Modal, Button} from 'react-bootstrap'
import { useEffect } from "react"

const CreateModal = (props) => {
    let actionItem = props.actionValue
    // console.log("actionItem:", actionItem)
    const [inputData, setInputData] = useState({
        application_name: "",
        modal: "",
        prompt_system:"",
        prompt_user: "",
        modified_by:"",
        form_type: "add"
    })
    const [modalName, setModalName] = useState("")
    const [applicationName, setApplicationName] = useState("")
    const [featureName, setFeatureName] = useState("")
    const [errors, setErrors] = useState({});

    const validateForm = () => {
      const newErrors = {};
      console.log("InputValue:", inputData)
      if(!inputData.application_name){
        newErrors.application_name = 'Application name required'
      }else if(!inputData.feature_name){
        newErrors.feature_name = 'Feature name required'
      }else if(!inputData.modal){
        newErrors.modal = 'Modal name required'
      }else if(!inputData.modified_by){
        newErrors.modified_by = 'Updated By required'
      }else if(!inputData.prompt_user){
        newErrors.prompt_user = 'User Prompt required'
      }else if(!inputData.prompt_system){
        newErrors.prompt_system = 'System Prompt required'
      }
      
      setErrors(newErrors)
      
        return newErrors
      
    }
    
    useEffect(async () => {
      await axios
        .post(`/openai/gpt/getModalName`, {})
        .then((res) => {
          const options = res.data.map(d => ({
            "value" : d.id,
            "label" : d.model_name
          }))
          setModalName(options);
        });
        await axios
        .post(`/openai/gpt/getApplicationName`, {})
        .then((res) => {
          const options = res.data.map(d => ({
            "value" : d.application_id,
            "label" : d.application_name
          }))
          setApplicationName(options);
        });
        await axios
        .post(`/openai/gpt/getFeatureName`, {})
        .then((res) => {
          const options = res.data.map(d => ({
            "value" : d.id,
            "label" : d.feature_name
          }))
          setFeatureName(options);
        });
    }, []);
    const handleClose = (event) => {
      // props.toggle
      window.location.reload();
    }
    const handleSubmit = (event) => {
            event.preventDefault()
            
            let validate = validateForm();
            if(Object.keys(validate).length==0){
              axios.post('/openai/gpt/addPrompt', inputData)
              .then(res => {
                  alert(res.data.result)
                  window.location.reload();
              })
            }
    }
    return (
      <div>
        {/* passing in the isOpen prop from the container */}
        <Modal show={props.isOpen} className="modal-dialog-style">
          <Modal.Header>
            <Modal.Title>
              <div className="container">
                <div className="col-md-9">
                  <div id="modal-message" className="col-md-9">
                    Create
                  </div>{" "}
                </div>
              </div>
            </Modal.Title>
            <div
              id="update-modal-exit"
              className="col-sm-2"
              // onClick={props.toggle}
              onClick={(event) => handleClose(event)}
            >
              X
            </div>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mrbot10">
                  <label htmlFor="application_name" className="col-md-9">
                    Application Name
                  </label>
                  <div>
                  <Select options={applicationName} onChange={(event) => {
                      setInputData({ ...inputData, application_name: event });
                    }}/>
                </div>
                  {errors.application_name && <p>{errors.application_name}</p>}
                </div>
                <div className="col-md-6 mrbot10">
                  <label htmlFor="feature_name" className="col-md-9">
                    Feature Name
                  </label>
                  <div>
                  <Select options={featureName} onChange={(event) => {
                      setInputData({ ...inputData, feature_name: event });
                    }}/>
                </div>
                  {errors.feature_name && <p>{errors.feature_name}</p>}
                </div>
              </div>
              <div className="row">
                {/* {console.log("Data:", updateData[actionItem] ? updateData[actionItem].name : "")} */}
  
                <div className="col-md-6 mrbot10">
                  <label htmlFor="modal" className="col-md-12">
                    Modal Name
                  </label>
                  <Select options={modalName} onChange={(event) => {
                      setInputData({ ...inputData,modal: event });
                    }}/>
                    {errors.modal && <p>{errors.modal}</p>}
                </div>
                <div className="col-md-6 mrbot10">
                  <label htmlFor="modified_by" className="col-md-12">
                    Updated By
                  </label>
                  <input
                    type="text"
                    name="modified_by"
                    className="form-control"
                    onChange={(e) => {
                      setInputData({ ...inputData, modified_by: e.target.value });
                    }}
                    autoComplete="off"
                  />
                  {errors.modified_by && <p>{errors.modified_by}</p>}
                </div>
                
              </div>
              <div className="row">
                <div className="col-md-12 mrbot10">
                  <label htmlFor="prompt_user">User Prompt</label>  
                  <textarea
                    type="text"
                    name="prompt_user"
                    className="form-control"
                    onChange={(e) => {
                      setInputData({ ...inputData, prompt_user: e.target.value });
                    }}
                    cols="60" rows="4"
                    autoComplete="off"
                  />
                </div>
                {errors.prompt_user && <p>{errors.prompt_user}</p>}                
              </div>
              <div>
                <label htmlFor="prompt_system">System Prompt</label>  
                <textarea
                  name="prompt_system"
                  className="form-control"
                  onChange={(e) => {
                    setInputData({ ...inputData, prompt_system: e.target.value });
                  }}
                  cols="60" rows="4"
                  autoComplete="off"
                />
                {errors.prompt_system && <p>{errors.prompt_system}</p>}
              </div>              
              <br />
              <button className="btn btn-info">
                Submit
              </button>
            </form>
  
            {/* </div> */}
          </Modal.Body>
        </Modal>
      </div>
    );
}

export default CreateModal;
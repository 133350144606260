import React, { useEffect } from "react"
import axios from "axios"
import { useState } from "react"
import CreateModal from "../src/CustomPromptUtility/CreateModal"
import UpdateModal from "../src/CustomPromptUtility/UpdateModal"
import { Link } from 'react-router-dom';
import { alignPropType } from "react-bootstrap/esm/DropdownMenu"
import { faAlignCenter } from "@fortawesome/free-solid-svg-icons"
import CustomPagination from './CustomPromptUtility/CustomPagination';
class App extends React.Component {

  // setting the initial state for the modal as hidden
  state = {
    showModel: false,
    showResponseData: false,
    responseData: [],
    updateResponseData: [],
    showModel: "",
    modelname: [],
    applicationname: [],
    featurename: [],
    isLoading: false,
    isLoadingAccord: false,
    responseAccord: [],
    showResponseAccordianData: false
  }

  // creating a toggle function that will be passed down to any children of this container that need to toggle the modal on or off
  toggleModel = (event) => {
    this.setState({ showModel: !this.state.showModel })
    let IncrValue = event.target.value
    // IncrValue = ++IncrValue
    this.setState({ showModel: IncrValue })
  }
  componentDidMount = async () => {
    await axios.post("/openai/gpt/getPromptList")
      .then((res) => {
        this.setState({ showResponseData: true })
        this.setState({ responseData: res.data })
        // console.log("Response:", this.state.responseData)
      })
      .catch(err => console.log(err))

      let applicationArr = []
      let modelArr = []
      let featureArr = []
    await axios
      .post(`/openai/gpt/getApplicationName`, {})
      .then((res) => {
        res.data.map(d => applicationArr.push({
          "value": d.application_id,
          "label": d.application_name
        }))
        this.setState({ applicationname: applicationArr });
      });
      await axios
      .post(`/openai/gpt/getModalName`, {})
      .then((res) => {
        res.data.map(d => modelArr.push({
          "id": d.model_name,
          "label": d.model_name
        }))
        this.setState({ modelname: modelArr })
      });
      await axios
      .post(`/openai/gpt/getFeatureName`, {})
      .then((res) => {
        res.data.map(d => featureArr.push({
          "value": d.feature_name,
          "label": d.feature_name
        }))
        this.setState({ featurename: featureArr })
      });
  }
  handleDelete = (id) => {
    let confirm = window.confirm("Are you sure to delete?")
    if (confirm) {
      axios.delete(`/openai/gpt/deletePrompt/${id}`).then((response) => {
        if (response.status == 200) {
          window.location.reload();
        }
      })
    }
  }
  handleAccordianClick = async (applicationId, index) => {
    this.setState({ isLoading: true })
    try {
      await axios.post("/openai/gpt/getPromptHistory", {application_id: applicationId})
        .then((res) => {
          this.setState({ showResponseData: true })
          this.setState({ responseAccord: res.data })
          this.setState({ isLoadingAccord: true })
          this.setState({ showResponseAccordianData: true })
        })
        .catch(err => console.log(err))

    } catch (e) { }
  }
  // handleUpdateClick = async (event, application_id) => {
  //   this.toggleModel(event)
  //   await axios.post("/openai/gpt/getPromptList", {application_id: application_id})
  //     .then((res) => {
  //       console.log("Response:", res.data)
  //       this.setState({ updateResponseData: res.data })
  //     })
  //     .catch(err => console.log(err))
  // }
  //
  render() {
    
    // Array.from({ length: 1 }, (_, index) => this.state.responseData);
    return (
      <div className="container ">
        <h3>Custom Prompt Utility</h3>
        {/* to="/CustomPromptUtility/Create" */}
        <button className="btn btn-success my-3" onClick={(event) => this.toggleModel(event)} value="Create">Create +</button>
        <div className="gridtable">
          <table className="table table-bordered">
            <thead className="">
              <tr>
                <th>Sr. No.</th>
                <th>Application Name</th>
                <th>Feature Name</th>
                <th>System Prompt</th>
                <th>User Prompt</th>
                <th>Model</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>

              {console.log(this.state.responseData)}
              {this.state.showResponseData && this.state.responseData.map((res, index) => {
                return (
                  
                  <tr key={res.application_id} value={res.application_id} onClick={(event) => this.handleAccordianClick(res.application_id, index)}>
                    <td style={faAlignCenter}>{index+1}</td>
                    <td><div disabled value={res.id}></div>{res.application_name}</td>
                    <td>{res.feature_name}</td>
                    <td>{res.prompt_system}</td>
                    <td>{res.prompt_user}</td>
                    <td>{res.model}</td>
                    <td>

                      <button className="btn btn-info" 
                      style={{ margin: "6px" }} 
                      onClick={(event) => this.toggleModel(event)} 
                      modelname={this.state.modelname} 
                      applicationname={this.state.applicationname} 
                      value={res.application_id}
                      >Update</button>
                      <button className="btn btn-danger" onClick={e => this.handleDelete(res.id)}>Delete</button>
                    </td>
                  </tr>
                )
              })
              }
              


            </tbody>
          </table>
          {/* <CustomPagination data={this.state.responseData} /> */}
        </div>
        {this.state.showModel != ""
          ? this.state.showModel == "Create" ? <CreateModal isOpen={this.state.showModel} toggle={this.toggleModel} /> : <UpdateModal isOpen={this.state.showModel} state={this.state} toggle={this.toggleModel} actionValue={this.state.showModel} />
          : ""}
      </div>
    )
  }
}

export default App;